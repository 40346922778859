<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="mt-5 mb-5"
            :width="800"
            hide-toggle
            centered>
            <template #heading>
                Install Event Flow
            </template>
            <div class="pa-5">
                <p>
                    Use the script below to activate BuyerBridge's ability to
                    track calls, retarget traffic and report on user behavior.
                    You can copy the script directly below or
                    <a
                        href="#"
                        @click.prevent="onDownloadEventFlowCode">
                        click here to download a zip file
                    </a>
                </p>
                <h3>Event Flow Script</h3>
                <p>
                    Please paste the script below on every page as high in
                    the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
                </p>
                <copy-text-area
                    :download-filename="`${uuid}-primary.txt`"
                    class="mb-4"
                    hide-email>
                    <pre>{{ mainScript }}</pre>
                </copy-text-area>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import StyledInterface from '@/components/globals/StyledInterface';
import CopyTextArea from '@/components/globals/CopyTextArea';
import {
    downloadEventFlowCode,
    getEventFlowScript
} from '@/helpers/eventFlowCode';

export default {
    title: 'Event Flow Script',
    components: {
        CopyTextArea,
        StyledInterface,
        StyledCard
    },
    data() {
        return {
            uuid: null
        };
    },
    computed: {
        mainScript() {
            return getEventFlowScript(this.uuid);
        },
    },
    created() {
        this.uuid = this.$route.params.uuid;
    },
    methods: {
        onDownloadEventFlowCode() {
            downloadEventFlowCode(this.uuid);
        }
    }
};
</script>
